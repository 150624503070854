import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

const Landing = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    });

    document.querySelectorAll('.fade-in').forEach((el) => observer.observe(el));
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-accent-900 via-accent-800 to-accent-900 text-primary-300 relative overflow-hidden">
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="binary-rain absolute inset-0 opacity-10">
          {[...Array(20)].map((_, i) => (
            <div key={i} className="binary-column" style={{ left: `${i * 5}%`, animationDelay: `${i * 0.3}s` }}>
              01
            </div>
          ))}
        </div>
        
        <div className="circuit-pattern absolute inset-0">
          {[...Array(6)].map((_, i) => (
            <motion.div
              key={i}
              className="circuit-line"
              initial={{ opacity: 0, pathLength: 0 }}
              animate={{ opacity: 0.1, pathLength: 1 }}
              transition={{ duration: 2, delay: i * 0.5, repeat: Infinity }}
              style={{
                top: `${i * 20}%`,
                left: '0',
                right: '0',
                height: '2px',
                background: 'linear-gradient(90deg, transparent 0%, #f59e0b 50%, transparent 100%)'
              }}
            />
          ))}
        </div>

        <div className="particles absolute inset-0">
          {[...Array(30)].map((_, i) => (
            <motion.div
              key={i}
              className="particle"
              initial={{ 
                x: Math.random() * window.innerWidth,
                y: Math.random() * window.innerHeight,
                scale: 0
              }}
              animate={{ 
                x: Math.random() * window.innerWidth,
                y: Math.random() * window.innerHeight,
                scale: [0, 1, 0]
              }}
              transition={{
                duration: Math.random() * 3 + 2,
                repeat: Infinity,
                repeatType: "reverse"
              }}
              style={{
                width: '4px',
                height: '4px',
                borderRadius: '50%',
                background: '#f59e0b',
                position: 'absolute'
              }}
            />
          ))}
        </div>

        <div className="tech-grid absolute inset-0 opacity-5">
          <div className="grid-pattern"></div>
        </div>
      </div>

      <nav className="fixed w-full backdrop-blur-md bg-accent-900/30 z-50 border-b border-primary-500/20">
        <div className="max-w-7xl mx-auto px-6 py-4 flex justify-between items-center">
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="flex items-center gap-1"
          >
            <span className="text-3xl font-bold bg-gradient-to-r from-primary-400 to-primary-500 bg-clip-text text-transparent">
              Napta
            </span>
            <motion.span 
              className="text-3xl font-bold relative px-2"
              whileHover={{ scale: 1.05 }}
            >
              <span className="absolute inset-0 bg-gradient-to-r from-accent-800 to-accent-900 rounded-md border border-primary-500/30 backdrop-blur-sm"></span>
              <span className="relative z-10 bg-gradient-to-r from-primary-400 via-primary-300 to-primary-500 bg-clip-text text-transparent animate-pulse-slow">
                Ai
              </span>
              {/* Glowing effect */}
              <span className="absolute inset-0 rounded-md bg-primary-500/10 filter blur-sm"></span>
              {/* Animated border */}
              <motion.span 
                className="absolute inset-0 rounded-md"
                style={{
                  border: '1px solid transparent',
                  background: 'linear-gradient(90deg, transparent, rgba(245, 158, 11, 0.3), transparent) border-box',
                  WebkitMask: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)',
                  WebkitMaskComposite: 'destination-out',
                  maskComposite: 'exclude',
                }}
                animate={{
                  backgroundPosition: ['200% 0', '-200% 0'],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: 'linear'
                }}
              />
            </motion.span>
          </motion.div>
          <motion.div 
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="space-x-8 hidden md:flex items-center"
          >
            <a href="#features" className="text-primary-300 hover:text-primary-400 transition-colors">Features</a>
            <a href="#solutions" className="text-primary-300 hover:text-primary-400 transition-colors">Solutions</a>
            <a href="#contact" className="text-primary-300 hover:text-primary-400 transition-colors">Contact</a>
            <button className="bg-gradient-to-r from-primary-500 to-primary-400 px-6 py-2 rounded-full hover:shadow-lg hover:shadow-primary-500/20 transition-all hover:scale-105 text-accent-900">
              Get Started
            </button>
          </motion.div>
          <button className="md:hidden text-primary-300">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </nav>

      <main>
        <section className="min-h-screen flex items-center justify-center relative overflow-hidden">
          <div className="absolute inset-0 bg-[url('/public/grid.svg')] opacity-10 animate-pulse"></div>
          <div className="absolute inset-0 bg-gradient-radial from-primary-500/10 via-transparent to-transparent"></div>
          <div className="container mx-auto px-6 py-20 relative">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center max-w-4xl mx-auto"
            >
              <h2 className="text-5xl md:text-7xl font-bold mb-6 bg-gradient-to-r from-primary-400 to-primary-500 bg-clip-text text-transparent animate-gradient">
                The Future of AI Communication
              </h2>
              <p className="text-xl text-primary-300 mb-12 leading-relaxed">
                Revolutionize your business interactions with cutting-edge AI solutions for calls and chat
              </p>
              <div className="space-x-4 flex flex-col md:flex-row justify-center gap-4">
                <button className="bg-gradient-to-r from-primary-500 to-primary-400 px-8 py-4 rounded-full text-lg font-semibold hover:shadow-lg hover:shadow-primary-500/20 transition-all hover:scale-105 flex items-center justify-center gap-2 text-accent-900">
                  <span>Start Free Trial</span>
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </button>
                <button className="border border-primary-400 px-8 py-4 rounded-full text-lg font-semibold text-primary-300 hover:bg-primary-500/20 transition-all flex items-center justify-center gap-2">
                  <span>Watch Demo</span>
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </button>
              </div>
            </motion.div>
          </div>
        </section>

        <section id="features" className="py-20 bg-accent-900/50 relative">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-primary-500/5"></div>
          <div className="container mx-auto px-6 relative">
            <h2 className="text-4xl font-bold text-center mb-16 bg-gradient-to-r from-primary-400 to-primary-500 bg-clip-text text-transparent">
              Why Choose NAPTA?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="group p-8 rounded-2xl border border-primary-500/20 bg-gradient-to-b from-accent-900/50 to-accent-800/30 backdrop-blur-sm hover:border-primary-400/50 transition-all duration-300 hover:scale-105"
                >
                  <div className="text-4xl mb-4 group-hover:scale-110 transition-transform duration-300">{feature.icon}</div>
                  <h3 className="text-2xl font-bold mb-4 bg-gradient-to-r from-primary-400 to-primary-500 bg-clip-text text-transparent">{feature.title}</h3>
                  <p className="text-primary-300 leading-relaxed">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section id="solutions" className="py-20 relative">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold text-center mb-16 bg-gradient-to-r from-primary-400 to-primary-500 bg-clip-text text-transparent">
              Our Solutions
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
              {solutions.map((solution, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                  className="p-6 rounded-2xl border border-primary-500/20 bg-gradient-to-b from-accent-900/50 to-accent-800/30"
                >
                  <div className="flex items-center gap-4 mb-6">
                    <div className="text-3xl">{solution.icon}</div>
                    <h3 className="text-2xl font-bold bg-gradient-to-r from-primary-400 to-primary-500 bg-clip-text text-transparent">
                      {solution.title}
                    </h3>
                  </div>
                  <p className="text-primary-300 mb-6">{solution.description}</p>
                  <ul className="space-y-3">
                    {solution.features.map((feature, idx) => (
                      <li key={idx} className="flex items-center gap-2 text-primary-200">
                        <svg className="w-5 h-5 text-primary-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section id="contact" className="py-20 bg-accent-900/50 relative">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold text-center mb-16 bg-gradient-to-r from-primary-400 to-primary-500 bg-clip-text text-transparent">
              Get in Touch
            </h2>
            <div className="max-w-2xl mx-auto">
              <motion.form 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                className="space-y-6"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block text-primary-300 mb-2">Name</label>
                    <input 
                      type="text" 
                      className="w-full px-4 py-3 rounded-lg bg-accent-900/50 border border-primary-500/20 text-primary-100 focus:outline-none focus:border-primary-400"
                      placeholder="John Doe"
                    />
                  </div>
                  <div>
                    <label className="block text-primary-300 mb-2">Email</label>
                    <input 
                      type="email" 
                      className="w-full px-4 py-3 rounded-lg bg-accent-900/50 border border-primary-500/20 text-primary-100 focus:outline-none focus:border-primary-400"
                      placeholder="john@example.com"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-primary-300 mb-2">Message</label>
                  <textarea 
                    className="w-full px-4 py-3 rounded-lg bg-accent-900/50 border border-primary-500/20 text-primary-100 focus:outline-none focus:border-primary-400 h-32"
                    placeholder="Your message..."
                  ></textarea>
                </div>
                <button className="w-full bg-gradient-to-r from-primary-500 to-primary-400 py-4 rounded-lg text-accent-900 font-semibold hover:shadow-lg hover:shadow-primary-500/20 transition-all hover:scale-[1.02]">
                  Send Message
                </button>
              </motion.form>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

const features = [
  {
    title: "AI-Powered Calls",
    description: "Smart call handling with real-time AI assistance and analytics",
    icon: "🎯"
  },
  {
    title: "Intelligent Chat",
    description: "Context-aware chatbots delivering human-like interactions",
    icon: "💬"
  },
  {
    title: "Analytics Dashboard",
    description: "Comprehensive insights into your communication metrics",
    icon: "📊"
  }
];

const solutions = [
  {
    title: "Enterprise AI Suite",
    description: "Complete AI communication solution for large organizations",
    icon: "🏢",
    features: [
      "24/7 AI-powered customer support",
      "Multi-language support",
      "Custom AI model training",
      "Advanced analytics and reporting"
    ]
  },
  {
    title: "Small Business Package",
    description: "Affordable AI solutions scaled for growing businesses",
    icon: "🚀",
    features: [
      "Easy integration with existing systems",
      "Pay-as-you-grow pricing",
      "Basic analytics dashboard",
      "Email and chat support"
    ]
  },
  {
    title: "Developer API",
    description: "Flexible API access to our AI communication platform",
    icon: "⚡",
    features: [
      "RESTful API endpoints",
      "WebSocket support",
      "Comprehensive documentation",
      "Developer support channel"
    ]
  },
  {
    title: "Custom Solutions",
    description: "Tailored AI implementations for unique requirements",
    icon: "🎯",
    features: [
      "Dedicated solution architect",
      "Custom feature development",
      "Integration support",
      "Priority support line"
    ]
  }
];

export default Landing; 